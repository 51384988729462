<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="
      message-container-send
      mt-1
      d-flex
      align-center
      justify-end
      position-relative
    ">
    <v-sheet class="message-send d-flex flex-column" :class="{
      first: first,
      last: last,
      middle: middle,
    }">
      <div class="flex">
        {{ from }}
      </div>
      <template v-if="medias.length != 0">
        <v-sheet v-for="(media, index) in _imagesShow" :key="index" class="imagencard" dark elevation="1" :height="media.typeFile == 'image'
          ? '100'
          : media.typeFile == 'application'
            ? '80'
            : media.typeFile == 'video' || media.typeFile == 'audio'
              ? '125'
              : '100'
          " :width="media.typeFile == 'application'
            ? '80'
            : media.typeFile == 'image'
              ? '200'
              : media.typeFile == 'video' || media.typeFile == 'audio'
                ? '250'
                : '100'
            ">
          <template v-if="media.typeFile == 'image'">
            <v-img alt="No Image" height="100" width="185" contain :src="media.url"
              @click="dialogImg(media.url)"><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-if="media.typeFile == 'audio'">
            <vuetify-audio flat :file="media.url" color="success" downloadable></vuetify-audio>
          </template>
          <template v-if="media.typeFile == 'video'">
            <video width="185" height="100" controls>
              <source :src="media.url" :type="media.typeFile + '/' + media.type" />
            </video>
          </template>
          <template v-else-if="
            media.typeFile == 'application' || media.typeFile == 'text'
          ">
            <v-img height="80" width="80" contain :src="require('@/assets/img/documents.jpg')">
            </v-img>
          </template>

          <template v-if="media.typeFile != 'audio'">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="downloadUrl(media)" text v-on="on" v-bind="attrs" color="primary" class="botondownload">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download
                {{
                  media.typeFile == "image"
                    ? "Image"
                    : media.typeFile == "video"
                      ? "Video"
                      : media.typeFile == "audio"
                        ? "Audio"
                        : "Documents"
                }}</span>
            </v-tooltip>
          </template>
        </v-sheet>
        <v-dialog v-model="dialogImgShow" :width="width">
          <v-card class="imagencard">
            <v-img :max-height="600" :max-width="width" :src="image" contain alt="No image" @mousewheel="zoom">
            </v-img>
          </v-card>
        </v-dialog>
      </template>
      <span v-text="message"></span>
      <small class="text-right" v-text="getHours(createAt)"></small>
    </v-sheet>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { mapState } from "vuex";
import MessageMixin from "./messageMixin";

export default Vue.extend({
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  name: "chat-message-send",
  props: {
    medias: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [MessageMixin],
  data() {
    return {
      dialogImgShow: false,
      image: "",
      width: 800,
      images: [],
      imagesS: [],
      loading: false,
    };
  },

  async mounted() {
    if (this.medias.length > 0) {
      this.images = this.medias;

      await this.getImgs();
    } else {
      this.images = [];
    }
  },
  destroyed() {
    this.images = [];
  },

  computed: {
    ...mapState("crmSMSModule", ["contactSelect"]),

    _imagesShow() {
      if (this.imagesS.length == 0) {
        return [];
      }

      return this.imagesS;
    },
  },
  watch: {
    contactSelect: {
      async handler() {
        this.images = [];
        this.imagesS = [];
        setTimeout(async () => {
          this.images = this.medias;

          await this.getImgs();
        }, 500);
      },
    },
  },
  methods: {
    dialogImg(image) {
      this.image = image;
      this.dialogImgShow = true;
    },
    zoom(event) {
      //console.log(event);
    },

    downloadUrl(ima) {
      const imgs = ima.url + "." + ima.type;
      const a = document.createElement("A");
      a.href = ima.url;
      a.target = "_blank";
      a.setAttribute("style", "display: none");
      a.download = imgs.split("/")[3];
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getImgs() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      if (this.medias.length != 0) {
        for (let index = 0; index < this.medias.length; index++) {
          let response = {};
          const element = this.medias[index];

          if (typeof element == "string") {
            if (element.includes(apiBaseUrl)) {
               response = await axios({
              method: "get",
              url: element,
              responseType: "blob",
              headers: auth,
            });

            const typeFile = response.headers["content-type"].split("/")[0];
            const tp = response.headers["content-type"].split("/")[1];
            const buffer = new Blob([response.data], {
              type: tp,
            });

            let file_path = URL.createObjectURL(buffer);

            this.images[index] = {
              url: file_path,
              type: tp,
              typeFile: typeFile,
               old:element
            };
            this.imagesS.push({ url: file_path, type: tp, typeFile: typeFile, old:element });
            }
           
          } else {
            if (this.message == "voicemail") {
              this.images[index] = {
                url: element,
                type: "wav",
                typeFile: "audio",
              };
              this.imagesS.push({
                url: element,
                type: "wav",
                typeFile: "audio",
              });
            } else {
            if (typeof element=='object') {
              this.images[index]=element
              this.imagesS.push(element)
             }else{
              this.images[index] = {
                url: element.url,
                type: element.type,
                typeFile: element.typeFile,
              };
              this.imagesS.push({
                url: element,
                type: element.type,
                typeFile: element.typeFile,
              });
             }
            }
          }
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.imagencard {
  position: relative;
}

.botondownload {
  position: absolute;
  bottom: -10px;
  left: -25px;
}

.message-container-send {
  width: 400px !important;
  align-self: flex-end;
  margin: 10px 0;

  &:hover .time {
    opacity: 1;
  }

  .time {
    position: absolute;
    top: 50%;
    left: -54px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #90a4ae;
    opacity: 0;
    transition: opacity var(--crm-transition-duration) ease;
  }

  .message-send {
    position: relative;
    background-color: var(--v-primary-base);
    color: #fff;
    padding: 6px 15px;
    text-align: left;
    border-radius: 20px;
    font-size: 12px;
    word-break: break-word;
    max-width: 380px !important;

    &.middle {
      border-radius: 20px 5px 5px 20px;
    }

    &.first {
      border-radius: 20px 20px 5px;
    }

    &.last {
      border-radius: 20px 5px 20px 20px;
    }
  }
}
</style>
